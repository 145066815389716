import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout";
import SEO from "../components/seo";
import Carousel from "../components/carousel";
export const _frontmatter = {
  "title": "Building with Gatsby",
  "author": "Jay Gatsby"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Home" mdxType="SEO" />
    <p><h1>{`{props.pageContext.frontmatter.title}`}</h1></p>
    <span>{props.pageContext.frontmatter.author}</span>
    <Carousel mdxType="Carousel" />
    <h3>{`Hello`}</h3>
    <p>{`(Blog post content, components, etc.)`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      